import React from 'react'
import { LayoutIR } from '../../components/organisms/Layout/LayoutIR'
import { LinkBase } from '../../components/atoms/Link/LinkBase'
import { SEOIR } from '../../components/data/SEO'
import { ENGLISH_TITLE } from '../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="電子公告"
      headingEnglish={ENGLISH_TITLE.PublicNotice}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '電子公告', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <div className="pt-6 pb-6 px-6 pc:px-4 leading-normal border-t border-b border-border10">
          ただいま掲載する事項はございません。
        </div>
        <div className="px-6 py-8 border-b border-border10">
          <p className="mb-8 pc:mb-8 leading-normal">
            当社の決算公告については会社法第440条第4項の規定に基づき、掲載しておりません。
            <br />
            なお、当社の貸借対照表及び損益計算書については、金融庁の下記サイトをご参照ください。
            <br />
          </p>
          <p className="mb-2 pc:mb-2 leading-normal">
            【EDINETコード】　CRGホールディングス株式会社：E34352
          </p>
          <div className="text-primary underline">
            <LinkBase href="https://disclosure.edinet-fsa.go.jp/">
              EDINETはこちら
            </LinkBase>
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="電子公告" url="/ir/notice" />
